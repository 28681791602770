@use "./colors.scss" as tokens;

.signup-container-form {
  overflow: hidden;

  .form-container {
    width: 85%;
  }

  .login-web-image {
    width: 50%;
    height: 100vh;
    background-image: url("../assets/images/MVS_login_web.webp");
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
  }

  .signup-logo-alignment {
    width: 30%;
    position: fixed;
    top: 40%;
    left: 10%;
  }

  .tick-login {
    background-image: url("../assets/images/tick-login.png");
    background-repeat: no-repeat;
    background-position: 20px;
    width: 460px;
    height: 480px;
    position: fixed;
    bottom: 0px;
    right: 0;
    z-index: -1;
    background-size: contain;
  }

  .btn-login {
    width: 100%;
  }

  .btn-signup {
    width: 50%;
  }
  .forgot-password-label {
    font-size: 14px;
    font-weight: 400;
    color: tokens.$font-anchor-var-1;
    cursor: pointer;
  }

  .signup-label {
    font-size: 20px !important;
    color: #131844;
    font-weight: 700;
  }

  .outer-radio {
    border: 1px solid #000000;
    border-radius: 4px;
    padding: 5px 10px;
    .form-check-input:checked {
      background-color: #000000;
      border-color: #000000;
    }
  }

  .radio-box {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    border: 2px solid #000000;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    input {
      appearance: none;
      width: 16px;
      height: 16px;
      border: 2px solid black;
      border-radius: 50%;
      margin-right: 10px;
      display: inline-block;
      position: relative;
      &:checked {
        background-color: black;
      }
      &:focus {
        border-color: black;
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
      }
    }
    span {
      color: black;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }

  .radio-box input:checked + span {
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .radio-box:has(input:checked) {
    background-color: #50b84a80;
    color: black;
    border-color: #50b84a80;
    span {
      color: black;
    }
  }

  .radio-box-type-2 {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 2px solid #000000;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    input {
      appearance: none;
      width: 16px;
      height: 16px;
      border: 2px solid black;
      border-radius: 50%;
      margin: 0;
      margin-right: 10px;
      display: inline-block;
      position: relative;
      &:checked {
        background-color: black;
      }
      &:focus {
        border-color: black;
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
      }
    }
    span {
      color: black;
      border-radius: 5px;
    }
    
  }

  .radio-box-type-2 input:checked + span {
    color: black;
    border-radius: 5px;
  }

  .radio-box-type-2:has(input:checked) {
    background-color: #50b84a80;
    color: black;
    border-color: #50b84a80;
    span {
      color: black;
    }
  }

  .no-class input:checked + span {
    color: #FFFFFF;
    border-radius: 5px;
  }

  .no-class:has(input:checked) {
    background-color: rgba(0, 26, 255, 0.5);
    color: #FFFFFF;
    border-color: #001AFF;
    span {
      color: #FFFFFF;
    }
  }

  .sign-up-started {
    background-color: #131844;
    font-size: 16px;
    color: #ffffff;
    width: 140px;
    padding: 10px;
    border: none;
    &:hover {
      background-color: #131844 !important;
    }
    &:active {
      background-color: #131844 !important;
    }
    &:focus {
      background-color: #131844 !important;
    }
  }

  .change-title {
    font-size: 14px;
    color: #000000;
    font-weight: 400;
    cursor: pointer;
    width: fit-content;
  }

  .choose-title {
    font-size: 16px;
    color: #000000;

    span {
      &.highlight {
        font-weight: 700;
        color: #131844;
      }
    }
  }

  .signup-container {
    font-size: 14px;
    color: #302f56;
    font-weight: 700;
    .signup-btn {
      color: #13599e;
      cursor: pointer;
    }
  }

  .feedback-red {
    color: rgb(204, 0, 0);
    font-size: 0.875rem;
    font-weight: 400;
  }
}
