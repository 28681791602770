@use "./colors.scss" as tokens;

.login-container {
  overflow: hidden;

  .login-web-image {
    width: 100%;
    height: 100vh;
    background-image: url("../assets/images/MVS_login_web.webp");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .form-container {
    width: 70%;
    &.type-2 {
      width: 85%;
    }
  }

  .tick-login {
    background-image: url("../assets/images/tick-login.png");
    background-repeat: no-repeat;
    background-position: 20px;
    width: 460px;
    height: 480px;
    position: fixed;
    bottom: 0px;
    right: 0;
    z-index: -1;
    background-size: contain;
  }

  .btn-login {
    width: 100%;
  }
  .forgot-password-label {
    font-size: 14px;
    font-weight: 400;
    color: tokens.$font-anchor-var-1;
    cursor: pointer;
  }

  .signup-container {
    font-size: 14px;
    color: #302f56;
    font-weight: 700;
    .signup-btn {
      color: #13599e;
      cursor: pointer;
    }
  }

  .signup-label {
    font-size: 20px !important;
    color: #131844;
    font-weight: 700;
  }

  .radio-box {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    border: 2px solid #000000;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }

  .radio-box {
    input {
      appearance: none;
      width: 16px;
      height: 16px;
      border: 2px solid black;
      border-radius: 50%;
      margin-right: 2px;
      display: inline-block;
      position: relative;
      margin-top: 0;
      &:checked {
        background-color: black;
      }
      &:focus {
        border-color: black;
        box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.25);
      }
    }
    span {
      color: black;
      padding: 5px 10px;
      border-radius: 5px;
    }
  }

  .radio-box input:checked + span {
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
  }

  .radio-box:has(input:checked) {
    background-color: #50b84a80;
    color: black;
    border-color: #50b84a80;
    span {
      color: black;
    }
  }

  .sign-up-started {
    background-color: #131844;
    font-size: 16px;
    color: #ffffff;
    width: 140px;
    padding: 10px;
    border: none;
    &:hover {
      background-color: #131844 !important;
    }
    &:active {
      background-color: #131844 !important;
    }
    &:focus {
      background-color: #131844 !important;
    }
  }

  .feedback-red {
    color: rgb(204, 0, 0);
    font-size: 0.875rem;
    font-weight: 400;
  }
}

@media (max-width: 576px) {
  .login-container {
    overflow: hidden;

    .form-container {
      width: 90%;
      position: fixed;
      top: 50%;
      left: 50%;
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .tick-login {
      position: fixed;
      background-position: 0px;
      left: 10px;
      width: 100%;
      height: 480px;
      z-index: -1;
    }
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .login-container {
    overflow: hidden;

    .form-container {
      width: 90%;
      position: fixed;
      top: 50%;
      left: 50%;
      -ms-transform: translateX(-50%) translateY(-50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }

    .tick-login {
      position: fixed;
      background-position: 0px;
      -ms-transform: translateX(10%) translateY(80%);
      -webkit-transform: translate(10%, 80%);
      transform: translate(10%, 80%);
      z-index: -1;
      bottom: 50%;
      z-index: -1;
    }
  }
}

@media (min-width: 1600px) {
  .login-container {
    overflow: hidden;

    .tick-login {
      position: fixed;
      background-position: 0px;
      right: 10%;
      z-index: -1;
    }
  }
}

@media (max-width: 1200px) and (max-height: 600px) {
  .login-container {
    overflow: hidden;

    .tick-login {
      position: fixed;
      background-position: 0px;
      right: -60px;
      z-index: -1;
    }
  }
}

@media (min-width: 1024px) and (min-height: 1200px) {
  .login-container {
    overflow: hidden;

    .tick-login {
      position: fixed;
      background-position: 0px;
      right: -60px;
      z-index: -1;
      bottom: 25%;
    }
  }
}
